import { useSlobAuth, getLoginUrl } from "client/src/hooks/auth";
import { useGetActiveRouteData } from "client/src/hooks/route";
import { useQueryString } from "client/src/hooks/useQueryString";
import { isYourBenefitsExplorer } from "client/src/utils/benefits-explorer";
import { Navigate, useLocation } from "react-router-dom";

import { AUTH_TYPE, RouteData, VIEW_TYPE } from "shared/config/routeData";
import { CLIENT, BROKER, BEN_ADMIN } from "shared/rbac/roles";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { NavBarLoading } from "../Navigation/NavBarPage";
import type { RbacCheckTypeSync } from "shared/rbac/rbac";
import type { ClientId } from "shared/types/Client";

export const AuthRedirectHandler = ({ children }: { children: JSX.Element }) => {
  const { isLoading, redirect } = useShouldRedirect();

  if (isLoading) {
    return <NavBarLoading />;
  }
  return redirect ? <Navigate to={redirect} replace /> : children;
};

const useShouldRedirect = () => {
  const activeRouteData = useGetActiveRouteData();
  const location = useLocation();
  const queryString = useQueryString();

  const { isLoading, isAuthenticated, accessCheck } = useSlobAuth();

  const goRedirectOrHome = {
    isLoading: false,
    redirect: queryString.get("redirect") || RouteData.index.getPathTemplate(),
  };
  const goLogin = {
    isLoading: false,
    redirect: getLoginUrl(location),
  };
  const noAction = { isLoading: false, redirect: null };
  const loading = { isLoading: true, redirect: null };

  if (isLoading) {
    return loading;
  }

  if (!activeRouteData) {
    return loading;
  }

  switch (activeRouteData.authType) {
    case AUTH_TYPE.required:
      if (!isAuthenticated) {
        if (activeRouteData.path === RouteData.index.getPath() && isYourBenefitsExplorer) break;
        return goLogin;
      }
      break;
    case AUTH_TYPE.not_allowed:
      if (isAuthenticated) {
        return goRedirectOrHome;
      }
      break;
    case AUTH_TYPE.optional:
      break;
    case AUTH_TYPE.outside_signer:
      break;
    default:
      exhaustiveCheck(activeRouteData.authType);
      break;
  }

  switch (activeRouteData.viewType) {
    case VIEW_TYPE.auth:
      break;
    case VIEW_TYPE.internal: {
      if (!isAuthenticated) {
        return goLogin;
      }
      if (!isValidInternalAuth(accessCheck)) {
        return goRedirectOrHome;
      }
      break;
    }
    case VIEW_TYPE.hub: {
      if (!isAuthenticated) {
        return goLogin;
      }
      const clientId = activeRouteData.params.clientId;
      if (!isValidHubAuth(accessCheck, clientId ?? "")) {
        return goRedirectOrHome;
      }
      break;
    }
    case VIEW_TYPE.broker: {
      if (!isAuthenticated) {
        return goLogin;
      }
      if (!isValidBrokerAuth(accessCheck)) {
        return goRedirectOrHome;
      }
      break;
    }
    case VIEW_TYPE.benAdmin: {
      if (!isAuthenticated) {
        return goLogin;
      }
      if (!isValidBAAuth(accessCheck)) {
        return goRedirectOrHome;
      }
      break;
    }
    case VIEW_TYPE.clear: {
      if (!isAuthenticated) {
        return goLogin;
      }
      const clientId = activeRouteData.params.clientId;
      if (!isValidHubAuth(accessCheck, clientId ?? "")) {
        return goRedirectOrHome;
      }
      break;
    }
    case VIEW_TYPE.explorer:
    case VIEW_TYPE.basic:
    case VIEW_TYPE.outside_signer: {
      return noAction;
    }
    default: {
      exhaustiveCheck(activeRouteData.viewType);
    }
  }
  return noAction;
};

const isValidHubAuth = (accessCheck: RbacCheckTypeSync, clientId: ClientId) => {
  try {
    return (
      accessCheck("read", CLIENT, clientId).granted &&
      accessCheck("update", CLIENT, clientId).granted
    );
  } catch (error) {
    return false;
  }
};

const isValidInternalAuth = (accessCheck: RbacCheckTypeSync) => {
  try {
    return (
      accessCheck("read", CLIENT).granted &&
      accessCheck("update", CLIENT).granted &&
      accessCheck("delete", CLIENT).granted
    );
  } catch (error) {
    return false;
  }
};

const isValidBrokerAuth = (accessCheck: RbacCheckTypeSync) => {
  try {
    return accessCheck("read", BROKER).granted;
  } catch (error) {
    return false;
  }
};

const isValidBAAuth = (accessCheck: RbacCheckTypeSync) => {
  try {
    return accessCheck("read", BEN_ADMIN).granted;
  } catch (error) {
    return false;
  }
};
