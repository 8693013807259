import { viteConfig } from "client/src/utils/viteConfig";
import { RouteData } from "shared/config/routeData";
import slugify from "slugify";
import type { ExplorerPageId, ExplorerPageVanitySlug } from "shared/types/ExplorerPage";

export const isYourBenefitsExplorer = window.location.host.includes(
  viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL,
);

export function initYourBenefitsExplorer() {
  if (isYourBenefitsExplorer) {
    document.title = "Your Benefits Explorer";
  }
}

export const getBenefitsExplorerPath = (
  pageId: ExplorerPageId,
  pageSlug?: ExplorerPageVanitySlug,
) => {
  const pageKey = pageSlug ? slugify(pageSlug, { lower: true }) : pageId;
  return isYourBenefitsExplorer
    ? RouteData.benefitsExplorer.getPath(pageKey)
    : RouteData.benefitsExplorerInternal.getPath(pageKey);
};
